<template>
  <div id="login">
    <div class="login-form">
      <a-form
              id="formLogin"
              class="user-layout-login"
              ref="formLogin"
              :form="form"
              @submit="onSubmit"
      >
        <a-form-item>
          <img class="logo" src="../../assets/logo.png" />
          <div class="head">升华 双报系统</div>
        </a-form-item>
        <a-alert v-if="isLoginError" type="error" showIcon style="margin-bottom: 24px;" message="账户或密码错误" />
        <a-form-item>
          <a-input
                  size="large"
                  type="text"
                  placeholder="请输入帐户名"
                  v-decorator="[
                'mobileNumber',
                {rules: [{ required: true, message: '请输入帐户名' }]}
              ]"
          >
            <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }"/>
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input-password
                  size="large"
                  placeholder="请输入密码"
                  v-decorator="[
                'pwd',
                {rules: [{ required: true, message: '请输入密码' },{ pattern:  /^.{3,6}$/, message: '请输入3-6位密码！' }], validateTrigger: 'blur'}
              ]"
          >
            <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
          </a-input-password>
        </a-form-item>

        <a-form-item>
          <a-checkbox :checked="true"> 记住我 </a-checkbox>
          <a class="forgot" href=""> 忘记密码 </a>
        </a-form-item>

        <a-form-item style="margin-top:24px">
          <a-button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  class="login-button"
                  :loading="load"
          >确定</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a href="https://beian.miit.gov.cn/" class="set">京ICP备12034924号-2</a>
  </div>
</template>
<script>
  import md5 from 'js-md5';
  import { login,auth } from '@/api/login'
  export default {
    data(){
      return {
        form: this.$form.createForm(this),
        isLoginError:false,
        labelCol: { span: 6 },
        wrapperCol: { span: 24 },
        formState:{},
        load:false
      }
    },
    methods:{
      onSubmit (e) {
        e.preventDefault()
        this.load= true
        this.form.validateFields(async (err, values) => {
          // this.isLoginError = true
          if (!err) {
            values.pwd = md5(values.pwd)
            values.from = 'pc'
            login(values).then(res => {
              localStorage.setItem("token", res)
              this.info()
            })
          } else {
            setTimeout(() => {
              this.load = false
            }, 600)
          }
        })
      },
      info() {
        auth().then((res)=>{
          localStorage.setItem("info",JSON.stringify(res.permissions))
          localStorage.setItem("userInfo",JSON.stringify(res))
          localStorage.setItem("userName", res.userName)
          this.$store.commit('SET_INFO', res.permissions)
          this.$store.commit('SET_USERNAME', res.userName)
          if(localStorage.getItem('userInfo')){
            this.$router.push({path: '/'});
          }
        })
      }
    }
  };
</script>
<style lang="scss">
.set{
  text-align: center;
  display: block;
  position: fixed;
  bottom: 20px;
  left: calc(50% - 80px);
}
  #login {
    width: 100%;
    height: 100%;
    background: url(../../assets/img/background.svg);
    background-size: cover;
    .login-form {
      margin: auto;
      width: 340px;
      min-height: 20px;
      padding-top: 150px;
      .ant-input {
        border-radius: 4px;
        line-height: 42px;
        height: 42px;
      }
      .ant-btn {
        width: 100%;
        height: 42px;
      }
    }
    .logo {
      width: 60px !important;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      margin-left: 20px !important;
      border: none;
      background-color: transparent;
    }
    .head {
      width: 300px;
      font-size: 30px !important;
      font-weight: 700 !important;
      margin-left: 20px !important;
      line-height: 60px !important;
      margin-top: 10px !important;
      position: absolute !important;
      display: inline-block !important;
      height: 60px !important;
      color: #dc080c;
    }
    .desc {
      width: 100% !important;
      text-align: center !important;
      color: gray !important;
      height: 60px !important;
      line-height: 60px !important;
    }
    .forgot {
      float: right;
    }
  }
</style>
